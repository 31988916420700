<template>
  <div>
    <div class="main-wrapper">
      <navbar @onHide="onHide" :width="swidth"></navbar>
      <sidebar @onHide="onHide" :isHidden="isHide" :width="swidth"></sidebar>

      <div class="page-wrapper">
        <div class="content">
          <div class="row">
            <div class="col-lg-6 col-sm-6 col-12">
              <div class="dash-widget dash1">
                <div class="dash-widgetimg">
                  <span
                    ><img src="/images/icons/circle-image.svg" alt="img"
                  /></span>
                </div>
                <div class="dash-widgetcontent">
                  <h5>{{ transaction.not_scan }}</h5>
                  <h6>Not Scanned</h6>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-sm-6 col-12">
              <div class="dash-widget dash2">
                <div class="dash-widgetimg">
                  <span
                    ><img
                      src="/images/icons/circle-polaroidcamera.svg"
                      alt="img"
                  /></span>
                </div>
                <div class="dash-widgetcontent">
                  <h5>{{ transaction.not_captured }}</h5>
                  <h6>Order Scanned</h6>
                </div>
              </div>
            </div>
          </div>

          <!-- Desktop View -->
          <div class="card mb-0" v-if="swidth > 1000">
            <div class="card-body">
              <h4 class="card-title">Order To Be Captured</h4>
              <div class="table-responsive dataview">
                <table
                  class="table datatable"
                  aria-describedby="Capture data desktop"
                >
                  <thead>
                    <tr>
                      <th>ORDER-NO</th>
                      <th>Order Date</th>
                      <th>Marketplace</th>
                      <th class="text-center">Status</th>
                      <th class="text-end">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="ord in order" :key="ord.transid">
                      <td>
                        <a @click="showDetail(ord.transid)">{{
                          ord.transid
                        }}</a>
                      </td>
                      <td>{{ ord.transdate }}</td>
                      <td>
                        <span>{{ ord.mpid }}</span>
                      </td>
                      <td class="text-center">
                        <span class="capsule-success">
                          {{ ucFirst(ord.status) }}
                        </span>
                      </td>
                      <td class="text-end">
                        <button
                          class="btn btn-outline-info"
                          @click="camera(ord.mpid, ord.transid)"
                        >
                          Capture
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <!-- Mobile View -->
          <div class="card mb-0" v-if="swidth < 1000">
            <div class="card-body">
              <h4 class="card-title">Order To Be Captured</h4>
              <div class="table-responsive dataview">
                <table
                  class="table datatable"
                  aria-describedby="Capture data desktop"
                >
                  <thead>
                    <tr>
                      <th>ORDER-NO</th>
                      <th>Marketplace</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="ord in order" :key="ord.transid">
                      <td>
                        <a @click="showDetail(ord.transid)">{{
                          ord.transid
                        }}</a>
                      </td>
                      <td>
                        <span>{{ ord.mpid }}</span>
                      </td>
                      <td class="text-end">
                        <button
                          class="btn btn-outline-info"
                          @click="camera(ord.mpid, ord.transid)"
                        >
                          Capture
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Sidebar from "@/components/Sidebar.vue";
import Navbar from "@/components/Navbar.vue";
import axios from "axios";

export default {
  name: "CaptureView",
  components: {
    Sidebar,
    Navbar,
  },
  watch: {
    qty_scan: function () {
      if (this.qty_scan === this.selected_qty) {
        document.getElementById("qty-check").style.background = "#7bff00";
      }
    },
  },
  data() {
    return {
      isHide: false,
      pdfDocument: null,
      pdfPage: null,
      swidth: 0,
      barcode: null,
      showCam: false,
      text: "Scan",
      findItem: "",
      findOrder: "",
      qty_scan: 0,
      selected_qty: 0,
      selected_id: null,
      order: [],
      transaction: {},
    };
  },
  mounted() {
    this.isHide = this.$store.state.isHide;
    this.swidth = screen.width;
    this.getTransaction();
  },
  methods: {
    onDecode(result) {
      let audio = new Audio("/sound/beep.mp3");
      audio.play();

      if (result) {
        if (this.selected_id !== result) {
          setTimeout(() => {
            alert("Wrong Item!");
          }, 500);
        } else {
          setTimeout(() => {
            const items = this.findOrder.item;
            const find = items.filter((obj) => {
              return obj.id === result;
            });

            this.findItem = find[0];
            this.selected_qty = this.findItem.qty_order;
            if (this.findItem.qty_order > this.qty_scan) {
              this.qty_scan += 1;
              this.findItem.qty_check = this.qty_scan;
            }
          }, 500);
        }
      }
    },
    showScanner(id) {
      if (this.showCam) {
        this.showCam = false;
        this.text = "Scan";
        this.selected_id = null;
        this.findItem = "";
        this.qty_scan = 0;
        this.selected_qty = 0;
      } else {
        this.showCam = true;
        this.text = "Close";
        this.selected_id = id;
      }
    },
    getImage(filename) {
      return "/images/" + filename;
    },
    getMPLogo(filename) {
      return "/images/marketplace/" + filename;
    },
    showDetail(id) {
      const find = this.order.filter((obj) => {
        return obj.id === id;
      });

      this.findOrder = find[0];
    },
    closeDetail() {
      this.findOrder = "";
    },
    addQty() {
      if (this.qty_scan >= this.selected_qty) {
        this.qty_scan = this.selected_qty;
        this.findItem.qty_check = this.qty_scan;
        return;
      }

      this.qty_scan += 1;
      this.findItem.qty_check = this.qty_scan;
    },
    subQty() {
      if (this.qty_scan < 0) {
        this.qty_scan = 0;
        this.findItem.qty_check = this.qty_scan;
        return;
      }

      this.qty_scan -= 1;
      this.findItem.qty_check = this.qty_scan;
      if (this.qty_scan < this.selected_qty) {
        document.getElementById("qty-check").style.background = "#fff";
      }
    },
    onHide(value) {
      this.isHide = value;
    },
    camera(id, trans) {
      this.$router.push({
        name: "camera",
        params: { mpid: id, transid: trans },
      });
    },
    async getTransaction() {
      try {
        const { data } = await axios.get(
          "/capture/" + this.$store.getters.GET_AUTH_TOKEN
        );
        console.log(data);
        this.transaction = data;
        this.order = data.order;
      } catch (error) {
        console.log(error);
      }
    },
    ucFirst(words) {
      const arr = words.split(" ");
      for (let i = 0; i < arr.length; i++) {
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
      }

      const str2 = arr.join(" ");
      return str2;
    },
  },
};
</script>

<style>
.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000000b1;
}

.modal-card {
  position: relative;
  width: 98%;
  max-width: 860px;
  background: #fff;
  border-radius: 5px;
  margin: 10px auto;
}

.modal-headers {
  position: relative;
  width: 98%;
  margin: 5px auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 15px;
  line-height: 20px;
}

.capsule-success {
  padding: 5px 10px;
  background: rgb(12, 179, 129);
  border-radius: 50px;
  color: #fff;
}

.capsule-warning {
  padding: 5px 10px;
  background: rgb(255, 216, 24);
  border-radius: 50px;
  color: #fff;
}

.capsule-danger {
  padding: 5px 10px;
  background: rgb(194, 0, 0);
  border-radius: 50px;
  color: #fff;
}
</style>
