<template>
  <div>
    <loader v-if="startloading"></loader>

    <div class="main-wrapper">
      <navbar @onHide="onHide" :width="swidth"></navbar>
      <sidebar @onHide="onHide" :isHidden="isHide" :width="swidth"></sidebar>

      <div class="page-wrapper">
        <div class="content">
          <div class="row">
            <div class="col-lg-6 col-sm-6 col-12">
              <div class="dash-widget dash1" id="total-scan">
                <div class="dash-widgetimg">
                  <span
                    ><img src="/images/icons/circle-image.svg" alt="img"
                  /></span>
                </div>
                <div class="dash-widgetcontent">
                  <h5>{{ transaction.not_scan }}</h5>
                  <h6>Not Scanned</h6>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-sm-6 col-12">
              <div class="dash-widget dash2" id="total-capture">
                <div class="dash-widgetimg">
                  <span
                    ><img
                      src="/images/icons/circle-polaroidcamera.svg"
                      alt="img"
                  /></span>
                </div>
                <div class="dash-widgetcontent">
                  <h5>{{ transaction.not_captured }}</h5>
                  <h6>Not Captured</h6>
                </div>
              </div>
            </div>
          </div>

          <!-- Desktop View -->
          <div class="card mb-0" v-if="swidth > 1000">
            <div class="card-body">
              <h4 class="card-title">Marketplace Order</h4>
              <div class="table-responsive dataview">
                <table
                  class="table datatable"
                  aria-describedby="Order data desktop"
                >
                  <thead>
                    <tr>
                      <th>ORDER-NO</th>
                      <th>Order Date</th>
                      <th>Marketplace</th>
                      <th class="text-right">Price</th>
                      <th class="text-center">Total Item</th>
                      <th class="text-center">Status</th>
                      <th class="text-end">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="trx in transaction.order" :key="trx.transid">
                      <td>
                        <a @click="showDetail(trx.transid)">{{
                          trx.transid
                        }}</a>
                      </td>
                      <td>{{ trx.transdate }}</td>
                      <td>{{ trx.mpid }}</td>
                      <td class="text-right">
                        Rp. {{ formatPrice(trx.grand) }}
                      </td>
                      <td class="text-center">{{ trx.qty }}</td>
                      <td class="text-center">
                        <span class="capsule-warning">{{
                          ucFirst(trx.status)
                        }}</span>
                      </td>
                      <td class="text-end">
                        <button
                          class="btn btn-outline-info"
                          @click="showDetail(trx.transid)"
                        >
                          View Detail
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <!-- Mobile View -->
          <div class="card mb-0" v-if="swidth < 1000">
            <div class="card-body">
              <h4 class="card-title">Marketplace Order</h4>
              <div class="table-responsive dataview">
                <table
                  class="table datatable"
                  aria-describedby="Order data mobile"
                >
                  <thead>
                    <tr>
                      <th>ORDER-NO</th>
                      <th>Marketplace</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="ord in order" :key="ord.transid">
                      <td style="border-top: none">
                        <a @click="showDetail(ord.transid)">{{
                          ord.transid
                        }}</a>
                      </td>
                      <td class="productimgname" style="border-top: none">
                        <span style="margin-bottom: 5px; margin-top: 2px">{{
                          ord.mpid
                        }}</span>
                      </td>
                      <td style="border-top: none">
                        <button
                          class="btn btn-sm btn-outline-info"
                          @click="showDetail(ord.transid)"
                        >
                          View
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="findOrder !== ''" class="modal-container">
      <div class="overlay" @click="closeDetail"></div>
      <div class="modal-card">
        <div class="modal-headers">
          <div class="modal-title">
            <span style="font-size: 14pt">{{ findOrder.mpid }}</span>
            <span style="font-size: 11pt"
              >Order Number : {{ findOrder.transid }}</span
            >
            <span style="font-size: 11pt"
              >Order Date : {{ findOrder.transdate }}</span
            >
          </div>
        </div>

        <!-- Desktop -->
        <div
          class="table-responsive"
          style="overflow-x: scroll"
          v-if="swidth > 860"
        >
          <table class="table datanew" aria-describedby="Products data desktop">
            <thead>
              <tr>
                <th>Product Name</th>
                <th>SKU</th>
                <th class="text-center">Qty</th>
                <th class="text-center">Check</th>
                <th class="text-right">Unit Price</th>
                <th>Qty</th>
                <th class="text-right">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in findOrder.items" :key="item.id">
                <td>{{ item.name }}</td>
                <td>{{ item.kode_barang }}</td>
                <td class="text-center">{{ item.qty_order }}</td>
                <td class="text-center">{{ item.qty_check }}</td>
                <td class="text-right">
                  Rp. {{ formatPrice(item.unit_price) }}
                </td>
                <td>{{ item.qty_order }}</td>
                <td class="text-right">
                  <button
                    class="btn btn-outline-primary"
                    @click="showScanner(item.transid)"
                  >
                    Scan
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <!-- Mobile -->
        <div
          class="table-responsive"
          style="overflow-x: scroll"
          v-if="swidth < 860"
        >
          <table class="table datanew" aria-describedby="Products data mobile">
            <thead>
              <tr>
                <th>SKU</th>
                <th class="text-center">Qty</th>
                <th class="text-center">Check</th>
                <th class="text-right">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in findOrder.items" :key="item.transid">
                <td
                  style="border-top: none"
                  :class="{ 'text-success': item.qty_order === item.qty_check }"
                >
                  <span style="margin-bottom: 7px; margin-top: 8px">
                    {{ item.kode_barang }}
                  </span>
                </td>
                <td
                  class="text-center"
                  style="border-top: none"
                  :class="{ 'text-success': item.qty_order === item.qty_check }"
                >
                  {{ item.qty_order }}
                </td>
                <td
                  class="text-center"
                  style="border-top: none"
                  :class="{ 'text-success': item.qty_order === item.qty_check }"
                >
                  {{ item.qty_check }}
                </td>
                <td class="text-right" style="border-top: none">
                  <button
                    class="btn btn-outline-primary"
                    v-if="item.qty_order !== item.qty_check"
                    @click="showScanner(item.kode_barang)"
                  >
                    Scan
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div class="show-camera" v-if="showCam">
      <div class="overlay" @click="showScanner"></div>
      <div class="barcode-camera-wrapper">
        <StreamBarcodeReader
          @decode="onDecode"
          @loaded="onLoaded"
          class="barcode-camera"
        >
        </StreamBarcodeReader>
      </div>

      <div class="scan-result" v-if="validBarcode">
        <div style="position: absolute; top: 5px; right: 10px">
          <span
            style="font-weight: bolder; color: #a00000; font-size: 16pt"
            @click="showScanner"
            >X</span
          >
        </div>

        <img
          v-if="findItem"
          :src="getImage(findItem.image)"
          :alt="findItem.image"
          style="width: 30%; object-fit: cover"
        />

        <div
          style="
            width: 60%;
            margin: 5px auto;
            display: flex;
            flex-direction: column;
          "
        >
          <span style="font-size: 16pt; font-weight: bold">{{
            findItem.name
          }}</span>
          <span>{{ findItem.category }}</span>
          <div
            style="
              margin-top: 10px;
              display: flex;
              flex-direction: row;
              margin-bottom: 20px;
              justify-content: space-between;
            "
          >
            <div
              style="
                width: 45%;
                border: 1px solid #545454;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 5px;
                font-size: 14pt;
              "
            >
              <span>Order : {{ findItem.qty_order }}</span>
            </div>

            <div
              style="
                width: 45%;
                height: 40px;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
              "
            >
              <div
                style="
                  width: 25%;
                  height: 100%;
                  font-size: 30pt;
                  border: 1px solid #545454;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  border-top-left-radius: 5px;
                  border-bottom-left-radius: 5px;
                "
                @click="subQty"
              >
                <span>-</span>
              </div>
              <div
                style="
                  width: 50%;
                  height: 100%;
                  border: 1px solid #545454;
                  font-size: 14pt;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                "
                id="qty-check"
              >
                <span>{{ findItem.qty_check }}</span>
              </div>
              <div
                style="
                  width: 25%;
                  height: 100%;
                  font-size: 20pt;
                  border: 1px solid #545454;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  border-top-right-radius: 5px;
                  border-bottom-right-radius: 5px;
                "
                @click="addQty"
              >
                <span>+</span>
              </div>
            </div>
          </div>
          <button
            class="btn btn-warning"
            v-if="findItem.qty_order === this.qty_scan"
            @click="
              submitScan(
                findItem.kode_barang,
                findOrder.mpid,
                findOrder.transid,
                findOrder.transdate
              )
            "
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  </div>

  <div v-show="showAlert" class="home-alert">
    <div
      class="alert-box fade-in-down"
      :class="{ 'bg-done': success, 'bg-warning': !success }"
      style="
        width: 80%;
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
      "
    >
      <div class="alert-message">
        <span class="alert-text">
          {{ message }}
        </span>
      </div>

      <div class="stripped-bar-success"></div>
    </div>
  </div>
</template>

<script>
import { StreamBarcodeReader } from "vue-barcode-reader";
import Sidebar from "@/components/Sidebar.vue";
import Navbar from "@/components/Navbar.vue";
import Loader from "@/components/Loader.vue";
import axios from "axios";

export default {
  name: "HomeView",
  components: {
    StreamBarcodeReader,
    Sidebar,
    Navbar,
    Loader,
  },
  watch: {
    qty_scan: function () {
      if (this.qty_scan === this.selected_qty) {
        document.getElementById("qty-check").style.background = "#7bff00";
      }
    },
  },
  data() {
    return {
      isHide: false,
      pdfDocument: null,
      pdfPage: null,
      swidth: 0,
      barcode: null,
      showCam: false,
      text: "Scan",
      findItem: "",
      findOrder: "",
      qty_scan: 0,
      selected_qty: 0,
      selected_id: null,
      transaction: [],
      order: [],
      showAlert: false,
      message: null,
      success: false,
      validBarcode: false,
      startloading: false,
    };
  },
  mounted() {
    this.isHide = this.$store.state.isHide;
    this.swidth = screen.width;
    this.getTransaction();
  },
  methods: {
    onDecode(result) {
      let audio = new Audio("/sound/beep.mp3");
      audio.play();

      if (result) {
        setTimeout(() => {
          const items = this.findOrder.items;
          const find = items.filter((obj) => {
            return obj.kode_barang === this.selected_id;
          });

          this.findItem = find[0];
          this.selected_qty = this.findItem.qty_order;
          console.log(result + " - " + this.findItem.kode_barcode);
          if (this.findItem.kode_barcode !== result) {
            this.validBarcode = false;
            setTimeout(() => {
              this.message = "Wrong Item!";
              this.success = false;
              this.showAlert = true;

              setTimeout(() => {
                this.showAlert = false;
                this.message = null;
              }, 2000);
            }, 500);
          } else {
            this.validBarcode = true;
            if (this.findItem.qty_order > this.qty_scan) {
              this.qty_scan += 1;
              this.findItem.qty_check = this.qty_scan;
            }
          }
        }, 500);
      }
    },
    async submitScan(id, mpid, transid, transdate) {
      try {
        const myDate = new Date();
        let myDay = myDate.getDate();
        let myMonth = myDate.getMonth() + 1;
        let myYear = myDate.getFullYear();

        if (myMonth <= 9) {
          myMonth = "0" + myMonth;
        }

        if (myDay <= 9) {
          myDay = "0" + myDay;
        }

        const tDate = new Date(Date.parse(transdate));
        let transDay = tDate.getDate();
        let transMonth = tDate.getMonth() + 1;
        let transYear = tDate.getFullYear();

        if (transMonth <= 9) {
          transMonth = "0" + transMonth;
        }

        if (transDay <= 9) {
          transDay = "0" + transDay;
        }

        const bodyData = {
          userid: this.$store.getters.GET_AUTH_INFO.id,
          kdbrg: id,
          mpid: mpid,
          transid: transid,
          transdate:
            String(transDay) +
            "-" +
            String(transMonth) +
            "-" +
            String(transYear),
          qtyscan: this.qty_scan,
          scan_date:
            String(myDay) + "-" + String(myMonth) + "-" + String(myYear),
        };

        await axios.post(
          "/transactions/" + this.$store.getters.GET_AUTH_TOKEN,
          bodyData
        );
        this.message = "Item Scanned!";
        this.success = true;
        this.showAlert = true;

        setTimeout(() => {
          this.showAlert = false;
          this.message = null;
          this.success = false;

          window.location.reload();
        }, 2000);
      } catch (error) {
        setTimeout(() => {
          this.message = error.response.data.message;
          this.success = false;
          this.showAlert = true;
        }, 2000);
      }
    },
    showScanner(id) {
      if (this.showCam) {
        this.showCam = false;
        this.text = "Scan";
        this.selected_id = null;
        this.findItem = "";
        this.qty_scan = 0;
        this.selected_qty = 0;
      } else {
        this.showCam = true;
        this.text = "Close";
        this.selected_id = id;
      }
    },
    getImage(filename) {
      return "/images/" + filename;
    },
    getMPLogo(filename) {
      return "/images/marketplace/" + filename;
    },
    showDetail(id) {
      const find = this.order.filter((obj) => {
        return obj.transid === id;
      });

      console.log(find);
      this.findOrder = find[0];
    },
    closeDetail() {
      this.findOrder = "";
    },
    addQty() {
      if (this.qty_scan >= this.selected_qty) {
        this.qty_scan = this.selected_qty;
        this.findItem.qty_check = this.qty_scan;
        return;
      }

      this.qty_scan += 1;
      this.findItem.qty_check = this.qty_scan;
    },
    subQty() {
      if (this.qty_scan < 0) {
        this.qty_scan = 0;
        this.findItem.qty_check = this.qty_scan;
        return;
      }

      this.qty_scan -= 1;
      this.findItem.qty_check = this.qty_scan;
      if (this.qty_scan < this.selected_qty) {
        document.getElementById("qty-check").style.background = "#fff";
      }
    },
    onHide(value) {
      this.isHide = value;
    },
    async getTransaction() {
      this.startloading = true;
      try {
        const { data } = await axios.get(
          "/transactions/" + this.$store.getters.GET_AUTH_TOKEN
        );
        this.transaction = data;
        this.order = data.order;
        console.log(this.transaction);
        this.startloading = false;
      } catch (error) {
        console.log(error);
      }
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    ucFirst(words) {
      const arr = words.split(" ");
      for (let i = 0; i < arr.length; i++) {
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
      }

      const str2 = arr.join(" ");
      return str2;
    },
  },
};
</script>

<style>
.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000000b1;
}

.modal-card {
  position: relative;
  width: 98%;
  max-width: 860px;
  background: #fff;
  border-radius: 5px;
  margin: 10px auto;
}

.modal-headers {
  position: relative;
  width: 98%;
  margin: 5px auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 15px;
  line-height: 20px;
}

.modal-title {
  display: flex;
  flex-direction: column;
  font-weight: bold;
  color: #545454;
  margin-left: 10px;
}

.show-camera {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  z-index: 2100;
}

.barcode-camera-wrapper {
  position: relative;
  width: 510px;
  height: auto;
  z-index: 10;
  border-radius: 5px;
}

.barcode-camera {
  position: relative;
  width: 100%;
  max-width: 510px;
  margin: 0 auto;
}

.scan-result {
  position: relative;
  width: 96%;
  max-width: 510px;
  background: #fff;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: -150px;
  z-index: 2110;
}

.home-alert {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  z-index: 50000;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
