<template>
  <!-- Desktop -->
  <div class="sidebar" id="sidebar" v-if="width > 768">
    <div style="width: 100%; margin-top: 50px">
      <ul>
        <li>
          <router-link
            :to="{ name: 'home' }"
            class="sidebar-link"
            active-class="link-active"
            id="sidebar-dashboard"
          >
            <img
              src="/images/icons/chart.svg"
              alt="screen"
              style="width: 30px; margin-right: 5px"
              v-if="this.$route.name !== 'home'"
            />

            <img
              src="/images/icons/chart-active.svg"
              alt="screen"
              style="width: 30px; margin-right: 5px"
              v-if="this.$route.name === 'home'"
            />
            <span v-if="!isHidden">Dashboard</span>
          </router-link>
        </li>
        <li>
          <router-link
            :to="{ name: 'capture' }"
            class="sidebar-link"
            active-class="link-active"
            id="sidebar-capture"
          >
            <img
              src="/images/icons/photo.svg"
              alt="screen"
              style="width: 30px; margin-right: 5px"
              v-if="this.$route.name !== 'capture'"
            />

            <img
              src="/images/icons/photo-active.svg"
              alt="screen"
              style="width: 30px; margin-right: 5px"
              v-if="this.$route.name === 'capture'"
            />
            <span v-if="!isHidden">Capture</span>
          </router-link>
        </li>
        <li>
          <router-link
            :to="{ name: 'import' }"
            class="sidebar-link"
            active-class="link-active"
            id="sidebar-import"
          >
            <img
              src="/images/icons/file.svg"
              alt="screen"
              style="width: 30px; margin-right: 5px"
              v-if="this.$route.name !== 'import'"
            />

            <img
              src="/images/icons/file-active.svg"
              alt="screen"
              style="width: 30px; margin-right: 5px"
              v-if="this.$route.name === 'import'"
            />
            <span v-if="!isHidden">Import</span>
          </router-link>
        </li>
      </ul>
    </div>
  </div>

  <!-- Mobile -->
  <div class="mobile-sidebar fade-in-left" v-if="isHidden && width < 768">
    <div
      style="position: absolute; top: 70px; right: 20px; z-index: 11"
      @click="closeSidebar"
    >
      <img
        src="/images/icons/close.svg"
        alt="close"
        style="width: 30px; cursor: pointer"
      />
    </div>

    <div style="width: 100%; margin-top: 110px">
      <ul>
        <li>
          <a
            @click="changeRoute('home')"
            class="sidebar-link"
            :class="{ 'link-active': this.$route.name === 'home' }"
          >
            <img
              src="/images/icons/chart.svg"
              alt="screen"
              style="width: 30px; margin-right: 5px"
              v-if="this.$route.name !== 'home'"
            />

            <img
              src="/images/icons/chart-active.svg"
              alt="screen"
              style="width: 30px; margin-right: 5px"
              v-if="this.$route.name === 'home'"
            />
            <span>Dashboard</span>
          </a>
        </li>
        <li>
          <a
            @click="changeRoute('capture')"
            class="sidebar-link"
            :class="{ 'link-active': this.$route.name === 'capture' }"
          >
            <img
              src="/images/icons/photo.svg"
              alt="screen"
              style="width: 30px; margin-right: 5px"
              v-if="this.$route.name !== 'capture'"
            />

            <img
              src="/images/icons/photo-active.svg"
              alt="screen"
              style="width: 30px; margin-right: 5px"
              v-if="this.$route.name === 'capture'"
            />
            <span>Capture</span>
          </a>
        </li>
        <li>
          <router-link
            :to="{ name: 'import' }"
            class="sidebar-link"
            :class="{ 'link-active': this.$route.name === 'import' }"
          >
            <img
              src="/images/icons/file.svg"
              alt="screen"
              style="width: 30px; margin-right: 5px"
              v-if="this.$route.name !== 'import'"
            />

            <img
              src="/images/icons/file-active.svg"
              alt="screen"
              style="width: 30px; margin-right: 5px"
              v-if="this.$route.name === 'import'"
            />
            <span>Import</span>
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { driver } from "driver.js";
import "driver.js/dist/driver.css";
export default {
  name: "SidebarVue",
  props: ["isHidden", "width"],
  mounted() {
    if (this.$store.getters.GET_FIRST_TIME && this.width > 1000) {
      const driverObject = driver({
        showButtons: ["next", "previous"],
        popoverClass: "driverjs-theme",
        showProgress: true,
        steps: [
          {
            element: "#sidebar-import",
            popover: {
              title: "Import",
              description: "First step import order data from Ginee here",
            },
          },
          {
            element: "#sidebar-dashboard",
            popover: {
              title: "Dashboard",
              description:
                "The second step is to scan the product barcode on this menu",
              side: "right",
              align: "start",
            },
          },
          {
            element: "#sidebar-capture",
            popover: {
              title: "Capture",
              description:
                "The final step is to take a photo of the product before packaging",
              side: "right",
              align: "start",
            },
          },
          {
            element: "#total-scan",
            popover: {
              title: "Not Scanned",
              description:
                "Number of orders that have not been scanned barcode",
              side: "bottom",
              align: "start",
            },
          },
          {
            element: "#total-capture",
            popover: {
              title: "Not Captured",
              description:
                "Number of orders for which photos have not been taken",
              side: "bottom",
              align: "start",
            },
          },
        ],
      });

      driverObject.drive();
      this.$store.commit("SET_FIRST_TIME", false);
    }
  },
  methods: {
    closeSidebar() {
      this.$store.commit("update_hide", false);
      this.$emit("onHide", this.$store.state.isHide);
    },
    changeRoute(url) {
      this.$store.commit("update_hide", false);
      this.$emit("onHide", this.$store.state.isHide);

      this.$router.push({ name: url });
    },
  },
};
</script>

<style>
.sidebar-link {
  width: 100%;
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  padding-left: 20px;
  font-size: 12pt;
  font-weight: bold;
  color: #585858;
  gap: 5px;
  text-decoration: none;
}

.link-active {
  color: rgb(255, 153, 0);
  background: #e4e4e4;
  text-decoration: none;
}

.mobile-sidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: #ffffff;
  z-index: 10;
}

.driver-popover.driverjs-theme {
  background-color: #ededed;
  color: #000;
}

.driver-popover.driverjs-theme .driver-popover-title {
  font-size: 20px;
}

.driver-popover.driverjs-theme .driver-popover-title,
.driver-popover.driverjs-theme .driver-popover-description,
.driver-popover.driverjs-theme .driver-popover-progress-text {
  color: #000;
}

.driver-popover.driverjs-theme button {
  flex: 1;
  text-align: center;
  background-color: #ff9f43;
  color: #ffffff;
  border: 2px solid #000;
  text-shadow: none;
  font-size: 14px;
  padding: 3px 3px;
  border-radius: 6px;
}

.driver-popover.driverjs-theme button:hover {
  background-color: #fe820e;
  color: #ffffff;
}

.driver-popover.driverjs-theme .driver-popover-navigation-btns {
  justify-content: space-between;
  gap: 3px;
}

.driver-popover.driverjs-theme .driver-popover-close-btn {
  color: #ffffff;
}

.driver-popover.driverjs-theme .driver-popover-close-btn:hover {
  color: #fe820e;
}

.driver-popover.driverjs-theme
  .driver-popover-arrow-side-left.driver-popover-arrow {
  border-left-color: #ededed;
}

.driver-popover.driverjs-theme
  .driver-popover-arrow-side-right.driver-popover-arrow {
  border-right-color: #ededed;
}

.driver-popover.driverjs-theme
  .driver-popover-arrow-side-top.driver-popover-arrow {
  border-top-color: #ededed;
}

.driver-popover.driverjs-theme
  .driver-popover-arrow-side-bottom.driver-popover-arrow {
  border-bottom-color: #ededed;
}
</style>
