import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import { auth } from './auth'

export default createStore({
  state: {
    isHide: false,
  },
  getters: {
  },
  mutations: {
    update_hide(state, payload){
      state.isHide = payload
    },
  },
  actions: {
  },
  modules: {
    auth
  },

  plugins: [createPersistedState({
    paths: ['auth']
  })]
})