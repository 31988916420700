<template>
  <div>    
    <div class="main-wrapper">
      <navbar @onHide="onHide" :width="swidth"></navbar>
      <sidebar @onHide="onHide" :isHidden="isHide" :width="swidth"></sidebar>

      <div class="page-wrapper">
        <div class="content">
          <!-- Desktop View -->
          <div class="card mb-0">
            <div class="card-body">
              <div class="d-flex flex-row justify-content-between mb-3">
                <h4 class="card-title">Import Transaction Data</h4>
                <button
                  class="btn btn-primary"
                  v-if="filelist.length > 0"
                  @click="submitOrder"
                >
                  <img
                    src="/images/icons/send.svg"
                    alt="camera"
                    style="width: 30px"
                  />
                  Submit
                </button>
              </div>

              <div
                class="flex w-full h-screen items-center justify-center text-center"
                id="app"
              >
                <div
                  class="p-12 bg-gray-100"
                  @dragover="dragover"
                  @dragleave="dragleave"
                  @drop="drop"
                  style="
                    position: relative;
                    height: 300px;
                    border: 3px dashed gray;
                    border-radius: 15px;
                    background: #f1f1f1;
                  "
                >
                  <button class="btn-upload" v-if="filelist.length <= 0">
                    Browse File
                    <input
                      type="file"
                      multiple
                      name="fields[assetsFieldHandle][]"
                      id="assetsFieldHandle"
                      class="w-px h-px opacity-0 overflow-hidden absolute"
                      @change="onChange"
                      ref="file"
                      accept=".xlsx,.xlsv,"
                      style="
                        opacity: 0;
                        position: absolute;
                        z-index: 10;
                        cursor: pointer;
                      "
                    />
                  </button>

                  <label for="assetsFieldHandle" class="block cursor-pointer">
                    <div class="drop-title">
                      <img
                        style="
                          position: relative;
                          width: 100px;
                          opacity: 0.5;
                          margin: 0 auto;
                        "
                        src="/images/icons/cloud.svg"
                        alt="icon"
                      />

                      <span>Drag & Drop File Here</span>
                    </div>
                  </label>

                  <!-- <ul class="mt-4 row" v-if="this.filelist.length" v-cloak>
                                <li class="text-sm p-1" v-for="file in filelist" :key="file">
                                    <button class="btn-upload" type="button" 
                                    @click="remove(filelist.indexOf(file))" 
                                    title="Remove file">Remove</button>
                                </li>
                            </ul> -->
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          style="width: 100%; display: flex; flex-direction: column; gap: 5px"
        >
          <span class="ml-4">File to upload:</span>
          <span
            style="
              position: relative;
              display: flex;
              flex-direction: row;
              align-items: center;
              gap: 5px;
              height: 40px;
              margin: 0 auto;
              background: rgb(237, 237, 237);
              border-radius: 5px;
              border: 2px dashed gray;
            "
            class="ml-4"
            v-for="fl in filelist"
            :key="fl"
          >
            <img
              src="/images/icons/excel.svg"
              alt=""
              style="width: 20px; margin-left: 5px"
            />
            {{ fl.name }}
            <img
              src="/images/icons/remove.svg"
              alt=""
              @click="remove(filelist.indexOf(fl))"
              style="
                width: 25px;
                margin-right: 5px;
                margin-left: 10px;
                cursor: pointer;
              "
            />
          </span>
        </div>
      </div>
    </div>
  </div>

  <div v-if="loading" class="loader-container">
    <div
      style="
        width: 100%;
        height: 100%;
        background: #ffffffdf;
        display: flex;
        justify-content: center;
        align-items: center;
      "
    >
      <div class="spinnerContainer">
        <div class="spinner"></div>
        <div class="loader">
          <p>Sending</p>
          <div class="words">
            <span class="word">transaction</span>
            <span class="word">data</span>
            <span class="word">request</span>
            <span class="word">order</span>
            <span class="word">products</span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    v-show="showAlert"
    style="position: fixed; width: 100vw; height: 100vh; top: 0"
  >
    <div
      style="
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: end;
        justify-content: end;
      "
    >
      <div
        class="alert-bottom fade-in-down"
        :class="{ 'bg-done': success, 'bg-warning': !success }"
        style="margin-bottom: 20px"
      >
        <div class="alert-message">
          <span
            style="
              margin-top: 12px;
              color: white;
              font-size: 12pt;
              font-weight: bold;
            "
          >
            {{ message }}
          </span>
        </div>

        <div class="stripped-bar-success"></div>
      </div>
    </div>
  </div>
</template>

<script>
import Sidebar from "@/components/Sidebar.vue";
import Navbar from "@/components/Navbar.vue";
import ExcelJS from "exceljs";
import axios from "axios";

export default {
  name: "LoadExcelView",
  components: {
    Sidebar,
    Navbar,
  },
  watch: {
    qty_scan: function () {
      if (this.qty_scan === this.selected_qty) {
        document.getElementById("qty-check").style.background = "#7bff00";
      }
    },
  },
  data() {
    return {
      isHide: false,
      pdfDocument: null,
      pdfPage: null,
      swidth: 0,
      filelist: [],
      show: false,
      fileContent: null,
      headers: [],
      content: [],
      body: [],
      message: null,
      showAlert: false,
      success: false,
      loading: false,
    };
  },
  mounted() {
    this.isHide = this.$store.state.isHide;
    this.swidth = screen.width;
  },
  methods: {
    onHide(value) {
      this.isHide = value;
    },
    onChange() {
      try {
        this.body = [];
        if (this.filelist.length <= 0) {
          this.filelist = [...this.$refs.file.files];
        }

        this.show = true;

        let j = 1;
        let i = 1;
        this.filelist.reverse().forEach((element) => {
          const workbook = new ExcelJS.Workbook();
          const reader = new FileReader();
          reader.onload = async (event) => {
            this.fileContent = event.target.result;
            const excelFile = await workbook.xlsx.load(this.fileContent);
            const worksheet = excelFile.getWorksheet(1);

            this.content = [];
            worksheet.eachRow((row, rowNumber) => {
              const rowData = row.values;
              if (rowData.length !== 75 && row.getCell(1).value) {
                alert("Document in wrong format!");
                this.filelist = [];
                return;
              }

              if (rowNumber === 1) {
                this.headers = row.values;
              } else {
                const data = {
                  no: i,
                  biaya_kartu_kredit: row.getCell(2).value,
                  waktu_pembaruan: this.convertDateProgress(row.getCell(3).value),
                  sinkronisasi_terakhir: row.getCell(4).value,
                  apakah_pesanan_palsu: row.getCell(5).value,
                  tanggal_pembuatan: this.convertDateProgress(row.getCell(6).value),
                  id_pesanan: row.getCell(7).value,
                  status: row.getCell(8).value,
                  jenis_pesanan: row.getCell(9).value,
                  channel: row.getCell(10).value,
                  nama_toko: row.getCell(11).value,
                  pembayaran: row.getCell(12).value,
                  waktu_pembayaran: this.convertDateProgress(row.getCell(13).value),
                  nama_pembeli: row.getCell(14).value,
                  tale_pembeli: row.getCell(15).value,
                  email_pembeli: row.getCell(16).value,
                  catatan_pembeli: row.getCell(17).value,
                  nama_produk: row.getCell(18).value,
                  variant_produk: row.getCell(19).value,
                  sku: String(row.getCell(20).value),
                  nama_gudang: row.getCell(21).value,
                  status_produk: row.getCell(22).value,
                  harga_awal_produk: row.getCell(23).value,
                  harga_promosi: row.getCell(24).value,
                  jumlah: row.getCell(25).value,
                  apakah_hadiah: row.getCell(26).value,
                  total_berat_gr: row.getCell(27).value,
                  harga_total_promosi: row.getCell(28).value,
                  subtotal: row.getCell(29).value,
                  invoice: row.getCell(30).value,
                  stock_sku: row.getCell(31).value,
                  nama_penerima: row.getCell(32).value,
                  no_hp_penerima: row.getCell(33).value,
                  alamat_penerima: row.getCell(34).value,
                  kurir: row.getCell(35).value,
                  provinsi: row.getCell(36).value,
                  kota: row.getCell(37).value,
                  kecamatan: row.getCell(38).value,
                  kode_pos: row.getCell(39).value,
                  kode_pelacakan: row.getCell(40).value,
                  dropshipper: row.getCell(41).value,
                  no_hp_dropshipper: row.getCell(42).value,
                  kirim_sebelum: row.getCell(43).value,
                  mata_uang: row.getCell(44).value,
                  total: row.getCell(45).value,
                  biaya_pengiriman: row.getCell(46).value,
                  biaya_kirim_ditanggung_pembeli: row.getCell(47).value,
                  pajak: row.getCell(48).value,
                  asuransi: row.getCell(49).value,
                  total_diskon: row.getCell(50).value,
                  platform_subsidy: row.getCell(51).value,
                  biaya_komisi: row.getCell(52).value,
                  biaya_layanan: row.getCell(53).value,
                  ongkir_dibayar_sistem: row.getCell(54).value,
                  potongan_harga: row.getCell(55).value,
                  subsidi_angkutan: row.getCell(56).value,
                  koin: row.getCell(57).value,
                  koin_cashback_penjual: row.getCell(58).value,
                  jumlah_pengembalian_dana: row.getCell(59).value,
                  voucher_channel: row.getCell(60).value,
                  diskon_penjual: row.getCell(61).value,
                  biaya_layanan_kartu_kredit: row.getCell(62).value,
                  catatan_penjual: row.getCell(63).value,
                  status_label_pengiriman: row.getCell(64).value,
                  status_invoice: row.getCell(65).value,
                  status_packing_list: row.getCell(66).value,
                  alasan_pembatalan: row.getCell(67).value,
                  waktu_pembatalan: this.convertDateProgress(row.getCell(68).value),
                  alamat_tagihan: row.getCell(69).value,
                  biaya_pembayaran: row.getCell(70).value,
                  biaya_lainnya: row.getCell(71).value,
                  komisi_lazpick_laztop: row.getCell(72).value,
                  maks_gratis_ongkos_kirim: row.getCell(73).value,
                  kredit_harga_barang: row.getCell(74).value,
                };

                this.content.push(data);
                i++;
              }
            });

            const dataBody = {
              no: j,
              order: [],
            };

            dataBody.order = this.content;
            this.body.push(dataBody);
            j++;
          };

          reader.readAsArrayBuffer(element);
        });

        console.log(this.body);
      } catch (error) {
        alert("Document in wrong format!");
      }
    },
    convertDateProgress(date){
      const newDate = new Date(date)
      const day = newDate.getDate()
      const month = newDate.getMonth() + 1
      const year = newDate.getFullYear()      
      return String(month) + '-' + String(day) + '-' + String(year)
    },
    remove(i) {
      this.filelist.splice(i, 1);
      this.body.splice(i, 1);
      this.show = false;

      if (this.filelist.length == 0) {
        this.filelist = [];
      }

      console.log(this.body);
    },
    dragover(event) {
      event.preventDefault();
      if (!event.currentTarget.classList.contains("bg-green-300")) {
        event.currentTarget.classList.remove("bg-gray-100");
        event.currentTarget.classList.add("bg-green-300");
      }
    },
    dragleave(event) {
      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-green-300");
    },
    drop(event) {
      event.preventDefault();
      const fileArray = event.dataTransfer.files;
      for (let file of fileArray) {
        if (
          file.type !==
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        ) {
          alert("Invalid file input: " + file.name + "!");
        } else {
          this.filelist.push(file);
        }
      }

      this.onChange();
      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-green-300");
    },
    async submitOrder() {
      this.loading = true;
      try {
        const bodyJson = {
          transaction: this.body,
        };

        const { data } = await axios.post("/import", bodyJson);
        this.loading = false;
        this.message = data.message;
        this.success = true;
        this.showAlert = true;

        setTimeout(() => {
          this.message = null;
          this.success = false;
          this.showAlert = false;
          this.filelist = [];
          this.body = [];
          this.headers = [];
          this.content = [];
        }, 2000);
      } catch (error) {
        this.loading = false;
        this.message = error.response.data.message;
        this.success = false;
        this.showAlert = true;

        setTimeout(() => {
          this.message = null;
          this.showAlert = false;
          this.filelist = [];
          this.body = [];
          this.headers = [];
          this.content = [];
        }, 2000);
      }
    },
  },
};
</script>

<style>
@import url("https://fonts.cdnfonts.com/css/costpretty");
.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000000b1;
}

.modal-card {
  position: relative;
  width: 98%;
  max-width: 860px;
  background: #fff;
  border-radius: 5px;
  margin: 10px auto;
}

.modal-headers {
  position: relative;
  width: 98%;
  margin: 5px auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 15px;
  line-height: 20px;
}

.btn-upload {
  position: absolute;
  width: 150px;
  height: 35px;
  left: 50%;
  bottom: 40px;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  cursor: pointer;
  background: var(--bluecolor);
  border: none;
  color: #fff;
}

.btn-upload:hover {
  background: var(--bluehover);
}

.drop-title {
  display: flex;
  flex-direction: column;
  font-size: 25pt;
  margin-top: 20px;
  font-family: "Costpretty", sans-serif;
}

.alert-bottom {
  position: relative;
  width: 300px;
  height: 80px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  margin-right: 20px;
}

.alert-bottom .close-btn {
  position: absolute;
  top: 5px;
  right: 10px;
  font-size: 21pt;
  color: var(--white);
}

.alert-bottom .alert-message {
  position: relative;
  width: 90%;
  margin: 0 auto;
  top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 12pt;
}
</style>
