<template>
  <div class="header">
    <div class="header-left active">
      <a class="logo logo-normal">
        <img src="/images/logo.png" alt="" />
      </a>

      <a href="index.html" class="logo logo-white">
        <img src="/images/logo-white.png" alt="" />
      </a>

      <a
        style="
          position: relative;
          width: 50%;
          object-fit: contain;
          margin: 0 auto;
        "
        v-if="ishide && width > 1000"
      >
        <img src="/images/logo-small.png" alt="" />
      </a>

      <a v-if="width > 1000" class="sidebar-toggle" @click="showSidebaar">
        <img
          v-if="ishide"
          src="/images/icons/maximize.svg"
          alt="max"
          style="width: 30px; margin-left: 11px"
        />

        <img
          v-if="!ishide"
          src="/images/icons/maximize.svg"
          alt="max"
          style="width: 30px; transform: scaleX(-1)"
        />
      </a>
    </div>

    <a id="mobile_btn" class="mobile_btn" href="#sidebar" @click="showSidebaar">
      <span class="bar-icon">
        <span></span>
        <span></span>
        <span></span>
      </span>
    </a>

    <ul v-if="width > 768" class="nav user-menu" style="justify-content: end">
      <li class="nav-item dropdown has-arrow main-drop">
        <a
          href="javascript:void(0);"
          class="dropdown-toggle nav-link userset"
          data-bs-toggle="dropdown"
        >
          <span class="user-info">
            <span class="user-letter">
              <img
                :src="getUserProfile(userimage)"
                alt=""
                class="img-fluid"
                style="border-radius: 7px"
              />
            </span>

            <span class="user-detail">
              <span class="user-name">{{ username }}</span>
              <span class="user-role">Super Admin</span>
            </span>
          </span>
        </a>

        <div class="dropdown-menu menu-drop-user">
          <div class="profilename">
            <div class="profileset">
              <span class="user-img">
                <img
                  :src="getUserProfile(userimage)"
                  alt=""
                  style="border-radius: 7px"
                />

                <span class="status online"></span>
              </span>
              <div class="profilesets">
                <h6>{{ username }}</h6>
                <h5>Super Admin</h5>
              </div>
            </div>

            <hr class="m-0" />
            <a class="dropdown-item">
              <i class="me-2" data-feather="user"></i> My Profile</a
            >
            <a class="dropdown-item"
              ><i class="me-2" data-feather="settings"></i>Settings</a
            >
            <hr class="m-0" />
            <a class="dropdown-item" @click="logout">
              <img src="/images/log-out.svg" class="me-2" alt="img" />Logout
            </a>
          </div>
        </div>
      </li>
    </ul>

    <div class="dropdown mobile-user-menu">
      <a
        href="javascript:void(0);"
        class="nav-link dropdown-toggle"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <i class="fa fa-ellipsis-v"></i>
      </a>
      <div class="dropdown-menu dropdown-menu-right">
        <a class="dropdown-item" href="profile.html">My Profile</a>
        <a class="dropdown-item" href="generalsettings.html">Settings</a>
        <a class="dropdown-item" @click="logout">Logout</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NavbarVue",
  props: ["width"],
  data() {
    return {
      ishide: false,
      show_sidebar: false,
      username: "",
      userimage: "",
    };
  },
  mounted() {
    this.getParams();
  },
  methods: {
    getParams() {
      this.show_sidebar = this.$store.state.isHide;
      this.ishide = this.$store.state.isHide;
      this.username = this.$store.getters.GET_AUTH_INFO.name;
      this.userimage = this.$store.getters.GET_AUTH_INFO.image;
    },
    getUserProfile(filename) {
      return this.uri + "/image/profile/" + filename;
    },
    showSidebaar() {
      if (this.show_sidebar) {
        this.$store.commit("update_hide", true);
        this.$emit("onHide", this.$store.state.isHide);
        this.ishide = this.$store.state.isHide;

        this.show_sidebar = false;
        document.body.classList.add("mini-sidebar");
      } else {
        this.$store.commit("update_hide", false);
        this.$emit("onHide", this.$store.state.isHide);
        this.ishide = this.$store.state.isHide;

        this.show_sidebar = true;
        document.body.classList.remove("mini-sidebar");
      }
    },
    logout() {
      if (this.is_proccess) return;
      this.is_proccess = true;

      const data = {
        id: String(this.$store.getters.GET_AUTH_INFO.id),
        access_token: this.$store.getters.GET_AUTH_TOKEN,
      };

      this.$store
        .dispatch("LOGOUT", data)
        .then(() => {
          this.is_proccess = false;
          window.location.href = "/";
        })
        .catch(() => {
          this.is_proccess = false;
          window.location.href = "/";
        });
    },
  },
};
</script>

<style>
.sidebar-toggle {
  position: absolute;
  right: -10px;
  width: 25px;
  height: 25px;
  border-radius: 500%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
</style>
