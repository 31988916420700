<template>
  <loader v-if="startloading"></loader>

  <div class="main-wrapper">
    <navbar @onHide="onHide" :width="swidth"></navbar>
    <sidebar @onHide="onHide" :isHidden="isHide" :width="swidth"></sidebar>

    <div class="page-wrapper">
      <div>
        <a @click="$router.go(-1)">
          <img
            style="
              position: absolute;
              width: 50px;
              margin-top: 30px;
              margin-left: 30px;
            "
            src="/images/icons/back.svg"
            alt="back"
          />
        </a>
      </div>
      <div class="content">
        <div
          style="
            width: 100%;
            display: flex;
            justify-content: end;
            margin-bottom: 20px;
            gap: 10px;
          "
        >
          <button
            v-if="isCaptured"
            class="btn btn-info text-white"
            style="display: flex; justify-content: center; align-items: center"
            @click="submitImage"
          >
            <img
              src="/images/icons/send.svg"
              alt="camera"
              style="width: 30px"
            />
            Submit
          </button>

          <button
            class="btn btn-primary"
            id="position-bottom-end"
            @click="capture"
            style="display: flex; justify-content: center; align-items: center"
          >
            <img
              src="/images/icons/capture.svg"
              alt="camera"
              style="width: 30px"
            />
            Capture
          </button>

          <button
            class="btn btn-primary"
            v-if="!isOpen"
            @click="init"
            style="display: flex; justify-content: center; align-items: center"
          >
            <img
              src="/images/icons/camera.svg"
              alt="camera"
              style="width: 30px"
            />
            Start Cam
          </button>

          <button
            class="btn btn-danger"
            style="display: flex; justify-content: center; align-items: center"
            v-if="isOpen"
            @click="closeCam"
          >
            <img
              src="/images/icons/camera.svg"
              alt="camera"
              style="width: 30px"
            />
            Close Cam
          </button>
        </div>

        <div class="row">
          <div class="col-lg-4 col-sm-6 col-12" style="position: relative">
            <label
              for="camera1box"
              v-if="!cam1"
              style="position: absolute; top: 7px; left: 20px"
            >
              {{ cam1_label }}
            </label>
            <select
              name="camera1box"
              v-model="cam1"
              :placeholder="cam1"
              class="form-control"
              @change="initCamera1(cam1)"
              style="margin-bottom: 10px"
            >
              <option
                v-for="cam in listDevices"
                :key="cam.device_id"
                :value="cam.device_id"
              >
                {{ cam.device_label }}
              </option>
            </select>

            <div
              class="dash-widget dash2"
              style="
                position: relative;
                height: 300px;
                padding: 10px;
                display: flex;
                justify-content: center;
              "
            >
              <img
                src="/images/icons/cam.svg"
                alt="cam"
                v-if="!isOpen"
                style="
                  position: absolute;
                  z-index: 10;
                  width: 70px;
                  opacity: 0.5;
                "
              />

              <span class="signal-banner" v-if="!isOpen"> No Signal </span>

              <div :class="{ 'tv-static': !isOpen }"></div>
              <video id="cam1" class="camera-canvas" autoplay muted></video>
            </div>
          </div>

          <div class="col-lg-4 col-sm-6 col-12" style="position: relative">
            <label
              for="camera2box"
              v-if="!cam2"
              style="position: absolute; top: 7px; left: 20px"
            >
              {{ cam1_label }}
            </label>
            <select
              name="camera2box"
              id=""
              v-model="cam2"
              :placeholder="cam2"
              class="form-control"
              @change="initCamera2(cam2)"
              style="margin-bottom: 10px"
            >
              <option
                v-for="cam in listDevices"
                :key="cam.device_id"
                :value="cam.device_id"
              >
                {{ cam.device_label }}
              </option>
            </select>

            <div
              class="dash-widget dash2"
              style="
                position: relative;
                height: 300px;
                padding: 10px;
                display: flex;
                justify-content: center;
              "
            >
              <img
                src="/images/icons/cam.svg"
                alt="cam"
                v-if="!isOpen"
                style="
                  position: absolute;
                  z-index: 10;
                  width: 70px;
                  opacity: 0.5;
                "
              />

              <span class="signal-banner" v-if="!isOpen"> No Signal </span>

              <div :class="{ 'tv-static': !isOpen }"></div>
              <video id="cam2" class="camera-canvas" autoplay muted></video>
            </div>
          </div>

          <div class="col-lg-4 col-sm-6 col-12" style="position: relative">
            <label
              for="camera3box"
              v-if="!cam3"
              style="position: absolute; top: 7px; left: 20px"
            >
              {{ cam1_label }}
            </label>
            <select
              name="camera3box"
              id=""
              v-model="cam3"
              :placeholder="cam3"
              class="form-control"
              @change="initCamera3(cam3)"
              style="margin-bottom: 10px"
            >
              <option
                v-for="cam in listDevices"
                :key="cam.device_id"
                :value="cam.device_id"
              >
                {{ cam.device_label }}
              </option>
            </select>

            <div
              class="dash-widget dash2"
              style="
                position: relative;
                height: 300px;
                padding: 10px;
                display: flex;
                justify-content: center;
              "
            >
              <img
                src="/images/icons/cam.svg"
                alt="cam"
                v-if="!isOpen"
                style="
                  position: absolute;
                  z-index: 10;
                  width: 70px;
                  opacity: 0.5;
                "
              />

              <span class="signal-banner" v-if="!isOpen"> No Signal </span>

              <span
                style="
                  position: absolute;
                  bottom: 100px;
                  z-index: 10;
                  width: 70px;
                  opacity: 0.5;
                "
              ></span>
              <div :class="{ 'tv-static': !isOpen }"></div>
              <video id="cam3" class="camera-canvas" autoplay muted></video>
            </div>
          </div>
        </div>

        <div class="result-text">
          <span>Result</span>
        </div>

        <div class="row">
          <div class="col-lg-4 col-sm-6 col-12">
            <div>
              <canvas class="image-holder" id="selectedpicture1"></canvas>
            </div>
          </div>

          <div class="col-lg-4 col-sm-6 col-12">
            <div>
              <canvas class="image-holder" id="selectedpicture2"></canvas>
            </div>
          </div>

          <div class="col-lg-4 col-sm-6 col-12">
            <div>
              <canvas class="image-holder" id="selectedpicture3"></canvas>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div v-if="loading" class="loader-container">
    <div
      style="
        width: 100%;
        height: 100%;
        background: #ffffffdf;
        display: flex;
        justify-content: center;
        align-items: center;
      "
    >
      <div class="spinnerContainer">
        <div class="spinner"></div>
        <div class="loader">
          <p>Sending</p>
          <div class="words">
            <span class="word">images</span>
            <span class="word">data</span>
            <span class="word">request</span>
            <span class="word">images</span>
            <span class="word">data</span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    v-show="showAlert"
    style="position: fixed; width: 100vw; height: 100vh; top: 0"
  >
    <div
      style="
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: end;
        justify-content: end;
      "
    >
      <div
        class="alert-bottom fade-in-down"
        :class="{ 'bg-done': success, 'bg-warning': !success }"
        style="margin-bottom: 20px"
      >
        <div class="alert-message">
          <span
            style="
              margin-top: 12px;
              color: white;
              font-size: 12pt;
              font-weight: bold;
            "
          >
            {{ message }}
          </span>
        </div>

        <div class="stripped-bar-success"></div>
      </div>
    </div>
  </div>
</template>

<script>
import Sidebar from "@/components/Sidebar.vue";
import Navbar from "@/components/Navbar.vue";
import Loader from "@/components/Loader.vue";
import axios from "axios";

export default {
  name: "CameraView",
  components: {
    Sidebar,
    Navbar,
    Loader,
  },
  data() {
    return {
      myTime: null,
      isOpen: false,
      isCaptured: false,
      listDevices: [],
      cam1: null,
      cam2: null,
      cam3: null,
      cam1_label: "Unknown Device",
      cam2_label: "Unknown Device",
      cam3_label: "Unknown Device",
      isHide: false,
      swidth: 0,
      mpid: null,
      transid: null,
      transdate: null,
      showAlert: false,
      message: null,
      success: false,
      loading: false,
      startloading: false,
    };
  },
  mounted() {
    this.isHide = this.$store.state.isHide;
    this.swidth = screen.width;
    // this.getTransaction()
  },
  methods: {
    async getTransaction() {
      try {
        this.startloading = true;
        const { data } = await axios.get(
          "/transaction/" +
            this.$route.params.mpid +
            "/" +
            this.$route.params.transid +
            "/" +
            this.$store.getters.GET_AUTH_TOKEN
        );

        if (data.status === "Captured") {
          this.$router.push({ name: "capture" });
        }

        this.mpid = data.mpid;
        this.transid = data.transid;
        this.transdate = data.transdate;
        this.startloading = false;
      } catch (error) {
        console.log(error);
      }
    },
    initCamera1(id) {
      if (
        "mediaDevices" in navigator &&
        "getUserMedia" in navigator.mediaDevices
      ) {
        navigator.mediaDevices
          .getUserMedia({
            audio: false,
            video: {
              deviceId: id,
              width: 1920,
              height: 1080,
            },
            focusMode: "continuous",
          })
          .then((stream) => {
            const videoLeft = document.getElementById("cam1");
            videoLeft.srcObject = stream;
            videoLeft.classList.add("mirrored");
          });
      } else {
        alert("mediaDevices not supported!");
      }
    },
    initCamera2(id) {
      if (
        "mediaDevices" in navigator &&
        "getUserMedia" in navigator.mediaDevices
      ) {
        navigator.mediaDevices
          .getUserMedia({
            audio: false,
            video: {
              deviceId: id,
              width: 1920,
              height: 1080,
            },
            focusMode: "continuous",
          })
          .then((stream) => {
            const videoLeft = document.getElementById("cam2");
            videoLeft.srcObject = stream;
            videoLeft.classList.add("mirrored");
          });
      } else {
        alert("mediaDevices not supported!");
      }
    },
    initCamera3(id) {
      if (
        "mediaDevices" in navigator &&
        "getUserMedia" in navigator.mediaDevices
      ) {
        navigator.mediaDevices
          .getUserMedia({
            audio: false,
            video: {
              deviceId: id,
              width: 1920,
              height: 1080,
            },
            focusMode: "continuous",
          })
          .then((stream) => {
            const videoLeft = document.getElementById("cam3");
            videoLeft.srcObject = stream;
            videoLeft.classList.add("mirrored");
          });
      } else {
        alert("mediaDevices not supported!");
      }
    },
    init() {
      this.listDevices = [];
      let i = 0;
      let defaultDevice;
      navigator.mediaDevices
        .enumerateDevices()
        .then((devices) => {
          devices.forEach((device) => {
            const obj = {
              device_label: "",
              device_id: "",
            };

            if (device.deviceId && device.kind === "videoinput") {
              if (i === 0) {
                defaultDevice = device.deviceId;
                this.cam1_label = device.label;
                this.cam2_label = device.label;
                this.cam2_label = device.label;
              }

              obj.device_label = device.label;
              obj.device_id = device.deviceId;
              this.listDevices.push(obj);
              i++;
            }
          });

          if (
            "mediaDevices" in navigator &&
            "getUserMedia" in navigator.mediaDevices
          ) {
            navigator.mediaDevices
              .getUserMedia({
                audio: false,
                video: {
                  deviceId: defaultDevice,
                  width: 1920,
                  height: 1080,
                },
                focusMode: "continuous",
              })
              .then((stream) => {
                const videoLeft = document.getElementById("cam1");
                videoLeft.srcObject = stream;
                videoLeft.classList.add("mirrored");

                const videoTop = document.getElementById("cam2");
                videoTop.srcObject = stream;
                videoTop.classList.add("mirrored");

                const videoRight = document.getElementById("cam3");
                videoRight.srcObject = stream;
                videoRight.classList.add("mirrored");

                this.isOpen = true;
              });
          } else {
            alert("mediaDevices not supported!");
          }
        })
        .catch(function (e) {
          this.message = e.name + ": " + e.message;
          this.status = false;
          this.showAlert = true;
        });
    },
    closeCam() {
      const screen1 = document.getElementById("cam1");
      const mediaStream1 = screen1.srcObject;
      const tracks1 = mediaStream1.getVideoTracks();
      tracks1.forEach((track) => track.stop());

      const screen2 = document.getElementById("cam2");
      const mediaStream2 = screen2.srcObject;
      const tracks2 = mediaStream2.getVideoTracks();
      tracks2.forEach((track) => track.stop());

      const screen3 = document.getElementById("cam3");
      const mediaStream3 = screen3.srcObject;
      const tracks3 = mediaStream3.getVideoTracks();
      tracks3.forEach((track) => track.stop());

      this.cam1 = null;
      this.cam2 = null;
      this.cam3 = null;
      this.cam1_label = null;
      this.cam2_label = null;
      this.cam3_label = null;
      this.firstAttemp = false;
      this.isOpen = false;
    },
    capture() {
      const pictures = document.getElementById("selectedpicture1");
      pictures.width = 1920;
      pictures.height = 1080;
      const ctx = pictures.getContext("2d");
      ctx.imageSmoothingEnabled = true;
      ctx.imageSmoothingQuality = "high";
      ctx.drawImage(
        document.getElementById("cam1"),
        0,
        0,
        pictures.width,
        pictures.height
      );

      const pictures1 = document.getElementById("selectedpicture2");
      pictures1.width = 1920;
      pictures1.height = 1080;
      const ctx1 = pictures1.getContext("2d");
      ctx1.imageSmoothingEnabled = true;
      ctx1.imageSmoothingQuality = "high";
      ctx1.drawImage(
        document.getElementById("cam2"),
        0,
        0,
        pictures.width,
        pictures.height
      );

      const pictures2 = document.getElementById("selectedpicture3");
      pictures2.width = 1920;
      pictures2.height = 1080;
      const ctx2 = pictures2.getContext("2d");
      ctx2.imageSmoothingEnabled = true;
      ctx2.imageSmoothingQuality = "high";
      ctx2.drawImage(
        document.getElementById("cam3"),
        0,
        0,
        pictures.width,
        pictures.height
      );

      this.isCaptured = true;
      this.images = true;
      this.is_captured = true;
    },
    onHide(value) {
      this.isHide = value;
    },
    async submitImage() {
      try {
        if (this.isCaptured) {
          if (!this.mpid || !this.transid || !this.transdate) {
            this.message = "Invalid Parameter!";
            this.success = false;
            this.showAlert = true;

            setTimeout(() => {
              this.showAlert = false;
              this.message = null;
            }, 2000);
            return;
          }

          this.loading = true;
          let j = 1;
          const tDate = new Date(Date.parse(this.transdate));
          let transDay = tDate.getDate();
          let transMonth = tDate.getMonth() + 1;
          let transYear = tDate.getFullYear();

          if (transMonth <= 9) {
            transMonth = "0" + transMonth;
          }

          if (transDay <= 9) {
            transDay = "0" + transDay;
          }
          const arrayIndex = [
            "selectedpicture1",
            "selectedpicture2",
            "selectedpicture3",
          ];
          const bodyData = {
            mpid: this.mpid,
            transid: this.transid,
            transdate:
              String(transDay) +
              "-" +
              String(transMonth) +
              "-" +
              String(transYear),
            image: [],
          };

          arrayIndex.forEach(async (el) => {
            console.log(j);
            const pictures = document.getElementById(el);
            const getImg = pictures.toDataURL("image/jpg");
            let byteString = atob(getImg.split(",")[1]);
            let mimeString = getImg.split(",")[0].split(":")[1].split(";")[0];
            let ab = new ArrayBuffer(byteString.length);
            let ia = new Uint8Array(ab);
            for (let i = 0; i < byteString.length; i++) {
              ia[i] = byteString.charCodeAt(i);
            }

            const fName =
              "Image-" +
              this.$route.params.transid +
              "-" +
              String(Math.floor(Math.random() * 1839763215)) +
              String(Date.now()) +
              ".jpg";

            const image = {
              id: j,
              name: fName,
            };
            bodyData.image.push(image);

            let myBlob = new Blob([ab], { type: mimeString });
            const myFile = new File([myBlob], fName, {
              type: myBlob.type,
            });

            console.log(myFile);

            const formData = new FormData();
            formData.append("filename", myFile);

            for (let pair of formData.entries()) {
              console.log(pair[0] + ", " + pair[1]);
            }

            await axios
              .post(
                "/upload_image/" + this.$store.getters.GET_AUTH_TOKEN,
                formData
              )
              .then((res) => {
                console.log(res);

                if (j == 3) {
                  axios
                    .post(
                      "/capture/" + this.$store.getters.GET_AUTH_TOKEN,
                      bodyData
                    )
                    .then(() => {
                      this.loading = false;
                      this.message = "Image Captured";
                      this.success = true;
                      this.showAlert = true;

                      setTimeout(() => {
                        this.showAlert = false;
                        this.message = null;
                        this.success = false;

                        this.$router.push({ name: "capture" });
                      }, 2000);
                    });
                }
              })
              .catch((err) => {
                this.loading = false;
                console.log(err);
              });

            j++;
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style>
.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000000b1;
}

.modal-card {
  position: relative;
  width: 98%;
  max-width: 860px;
  background: #fff;
  border-radius: 5px;
  margin: 10px auto;
}

.modal-headers {
  position: relative;
  width: 98%;
  margin: 5px auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 15px;
  line-height: 20px;
}

.mirrored {
  transform: scaleX(-1);
}

.image-holder {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  object-fit: cover;
}

.camera-canvas {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 8;
  background: #000;
  border-radius: 5px;
}

.tv-static {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 9;
  background-image: repeating-radial-gradient(
    circle at 17% 32%,
    white,
    black 0.00085px
  );
  background-position: center;
  animation: back 5s linear infinite;
  border-radius: 5px;
}

@keyframes back {
  from {
    background-size: 100% 100%;
  }
  to {
    background-size: 200% 200%;
  }
}

.signal-banner {
  position: absolute;
  bottom: 50px;
  width: 100%;
  height: 50px;
  z-index: 10;
  font-family: "Courier New", Courier, monospace;
  font-size: 22pt;
  font-weight: bolder;
  background: #0000004d;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.7;
}

.result-text {
  position: relative;
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #b2b2b2;
  border-radius: 5px;
  font-size: 18pt;
  margin-bottom: 20px;
  color: #fff;
  background-image: repeating-linear-gradient(
    -26deg,
    rgba(255, 255, 255, 0.25),
    rgba(255, 255, 255, 0.25) 2px,
    transparent 3px,
    transparent 7px
  );
  background-size: 6px 8px;
}

/* Spinner */
.spinnerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.spinner {
  width: 80px;
  height: 80px;
  display: grid;
  border: 4px solid #0000;
  border-radius: 50%;
  border-right-color: #ff9f43;
  animation: tri-spinner 1s infinite linear;
}

.spinner::before,
.spinner::after {
  content: "";
  grid-area: 1/1;
  margin: 2px;
  border: inherit;
  border-radius: 50%;
  animation: tri-spinner 2s infinite;
}

.spinner::after {
  margin: 8px;
  animation-duration: 3s;
}

@keyframes tri-spinner {
  100% {
    transform: rotate(1turn);
  }
}

.loader {
  color: #4a4a4a;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 25px;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  height: 40px;
  padding: 10px 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-radius: 8px;
  margin-top: 20px;
}

.words {
  overflow: hidden;
}

.word {
  display: block;
  height: 100%;
  padding-left: 6px;
  color: #ff9f43;
  animation: cycle-words 5s infinite;
}

@keyframes cycle-words {
  10% {
    -webkit-transform: translateY(-105%);
    transform: translateY(-105%);
  }

  25% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }

  35% {
    -webkit-transform: translateY(-205%);
    transform: translateY(-205%);
  }

  50% {
    -webkit-transform: translateY(-200%);
    transform: translateY(-200%);
  }

  60% {
    -webkit-transform: translateY(-305%);
    transform: translateY(-305%);
  }

  75% {
    -webkit-transform: translateY(-300%);
    transform: translateY(-300%);
  }

  85% {
    -webkit-transform: translateY(-405%);
    transform: translateY(-405%);
  }

  100% {
    -webkit-transform: translateY(-400%);
    transform: translateY(-400%);
  }
}

.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1100;
}
</style>
