<template>
  <div class="loader-container">
    <div
      style="
        width: 100%;
        height: 100%;
        background: #ffffffdf;
        display: flex;
        justify-content: center;
        align-items: center;
      "
    >
      <div class="spinnerContainer">
        <div class="spinner"></div>
        <div class="loader">
          <p>Loading</p>
          <div class="words">
            <span class="word">script</span>
            <span class="word">style</span>
            <span class="word">data</span>
            <span class="word">images</span>
            <span class="word">element</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LoaderVue",
};
</script>