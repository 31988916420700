<template>
  <div class="main-wrapper">
    <div class="account-content">
      <div class="login-wrapper">
        <div class="login-content">
          <div class="login-userset">
            <div class="login-logo logo-normal">
              <img src="images/logo.png" alt="img" />
            </div>

            <a href="index.html" class="login-logo logo-white">
              <img src="images/logo-white.png" alt="" />
            </a>

            <div class="login-userheading">
              <h3>Sign In</h3>
              <h4>Please login to your account</h4>
            </div>

            <div class="form-login">
              <label>Email</label>
              <div class="form-addons">
                <input
                  type="text"
                  placeholder="Enter your email address"
                  v-model="userid"
                  @keyup.enter="login"
                />
                <img src="images/mail.svg" alt="img" />
              </div>
              <span class="text-danger">{{ error_userid }}</span>
            </div>

            <div class="form-login">
              <label>Password</label>
              <div class="pass-group">
                <input
                  type="password"
                  class="pass-input"
                  @keyup.enter="login"
                  placeholder="Enter your password"
                  id="password"
                  v-model="password"
                />

                <span
                  class="fas toggle-password fa-eye-slash"
                  v-if="!show_password"
                  @click="showPassword"
                ></span>

                <span
                  class="fas toggle-password fa-eye"
                  v-if="show_password"
                  @click="showPassword"
                ></span>
              </div>
              <span class="text-danger">{{ error_password }}</span>
            </div>

            <div class="form-login" style="margin-bottom: 10px">
              <button class="btn btn-login" @click="login">Sign In</button>
            </div>

            <div v-if="showAlert" class="form-login" style="position: relative">
              <div
                style="position: absolute; top: 0; width: 100%; margin: 0 auto"
              >
                <div
                  v-if="success"
                  class="alert-box bg-done fade-in-down"
                  style="position: relative; margin: 0 auto"
                >
                  <div class="alert-message">
                    <span
                      style="
                        margin-top: 12px;
                        color: white;
                        font-size: 12pt;
                        font-weight: bold;
                      "
                    >
                      Login Success
                    </span>
                  </div>

                  <div class="stripped-bar-success"></div>
                </div>

                <div
                  v-if="!success"
                  class="alert-box bg-error fade-in-down"
                  style="position: relative; margin: 0 auto"
                >
                  <div class="alert-message">
                    <span
                      style="
                        margin-top: 12px;
                        color: white;
                        font-size: 12pt;
                        font-weight: bold;
                      "
                    >
                      {{ message }}
                    </span>
                  </div>

                  <div class="stripped-bar-success"></div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="login-img">
          <img src="/images/marketplace/cover.png" alt="img" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LoginView",
  data() {
    return {
      userid: "",
      password: "",
      error_userid: "",
      error_password: "",
      show_password: false,
      showAlert: false,
      success: false,
      message: "",
    };
  },
  methods: {
    showPassword() {
      if (this.show_password) {
        this.show_password = false;
        document.getElementById("password").type = "password";
      } else {
        this.show_password = true;
        document.getElementById("password").type = "text";
      }
    },
    login() {
      if (this.is_proccess) return;
      this.is_proccess = true;

      if (!this.userid) {
        this.error_userid = "User ID field is required!";
        setTimeout(() => {
          this.error_userid = "";
          this.is_proccess = false;
        }, 2000);
        return;
      }

      if (!this.password) {
        this.error_password = "Password field is required!";
        setTimeout(() => {
          this.error_password = "";
          this.is_proccess = false;
        }, 2000);
        return;
      }

      const data = {
        id: String(this.userid),
        password: this.password,
      };

      if (this.userid && this.password) {
        this.$store
          .dispatch("LOGIN", data)
          .then(() => {
            this.showAlert = true;
            this.success = true;

            setTimeout(() => {
              this.showAlert = false;
              this.success = false;
              this.$router.push({ name: "home" });
            }, 2000);
            this.is_proccess = false;
          })
          .catch((error) => {
            console.log(error);
            this.message = error.response.data.message;
            this.showAlert = true;
            this.success = false;

            console.log(error);
            setTimeout(() => {
              this.message = null;
              this.showAlert = false;
            }, 2000);
            this.is_proccess = false;
          });
      }
    },
  },
};
</script>

<style>
.bg-error {
  background-color: #ffd600;
  border-color: #e2bc00;
}

.bg-done {
  background-color: #6ad051;
}

.alert-box {
  position: relative;
  top: 10px;
  width: 100%;
  height: 80px;
  margin: 0 auto;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.alert-box .close-btn {
  position: absolute;
  top: 5px;
  right: 10px;
  font-size: 21pt;
  color: var(--white);
}

.alert-box .alert-message {
  position: relative;
  width: 90%;
  margin: 0 auto;
  top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 12pt;
}

.alert-box .alert-message .alert-text {
  margin-top: 12px;
  color: white;
  font-size: 12pt;
  font-weight: bold;
}

.stripped-bar-success {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 5px;
  background: white;
  animation: progressAnimationStrike 2s;
}

@keyframes progressAnimationStrike {
  from {
    width: 100%;
  }
  to {
    width: 0%;
  }
}
</style>
