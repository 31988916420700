import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import CaptureView from '../views/CaptureView.vue'
import CameraView from '../views/CameraView.vue'
import LoginView from '../views/LoginView.vue'
import TensorView from '../views/TensorView.vue'
import LoadExcelView from '@/views/LoadExcelView.vue'
import store from '../store'

const routes = [
  {path: '/', name: 'home', component: HomeView, meta: {login: true}},
  {path: '/capture', name: 'capture', component: CaptureView, meta: {login: true}},
  {path: '/camera/:mpid/:transid', name: 'camera', component: CameraView, meta: {login: true}},
  {path: '/login', name: 'login', component: LoginView, meta: {guest: true}},
  {path: '/import', name: 'import', component: LoadExcelView, meta:{login: true}},
  {path: '/tensor', name: 'tensor', component: TensorView},
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0);
  if(to.matched.some(record => record.meta.login)){
    if (!store.getters.GET_AUTH_TOKEN){
      next({
        name: 'login'
      })
    } else {
      next()
    }
  } else if(to.matched.some(record => record.meta.guest)){
    if (store.getters.GET_AUTH_TOKEN) {
      next({
        name: 'home'
      })
    } else {
      next()
    }
  } else {
    next()
  }
});

export default router
